// Core
import React, {useState, useRef, useEffect} from "react"
import { isMobile } from "react-device-detect"

// Components
import SEO from "../components/seo"

// Style
import styles from "./error.module.scss"

const NotFoundPage = () => {
  const [currentHeight, setCurrentHeight] = useState(0)

  const wrap = useRef()

  const checkForSubstantialHeightChange = () => {
    if (window.innerHeight > currentHeight - 100 && window.innerHeight < currentHeight + 100) return
    setHeight()
  }

  const setHeight = () => {
    if (wrap.current) wrap.current.style.height = window.innerHeight + 'px'
  }

  useEffect(() => {
    if (isMobile) setHeight()
    if (isMobile) window.addEventListener('resize', checkForSubstantialHeightChange)

    return () => {
      if (isMobile) window.removeEventListener('resize', checkForSubstantialHeightChange)
    }
  }, [])

  return (
    <section className={styles.wrap} ref={wrap}>
      <SEO
        title="404: Not found"
        />
      <div className={styles.headings}>
        <h1>404 Error</h1>
        <p className="h6">Page not found</p>
        <a href="/" className={`button`}>Back to home</a>
      </div>
    </section>
  )
}

export default NotFoundPage
